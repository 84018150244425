import React, {Component} from "react";
import Header from "../Home1/Header";
import HeroArea from "./HeroArea";
import AboutFeatured from "./AboutFeatured";
import FAQ from "../Home1/FAQ";

class aboutPage extends Component{
    render() {
        return(
            <div>
                <Header/>
                <HeroArea slug="WHO WE ARE" title="About Us"/>
                  <FAQ/>
            </div>
        )
    }
}
export default aboutPage;