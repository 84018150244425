import React, {Component} from "react";
import Header from "../Home1/Header";
import HeroArea from "../About/HeroArea";
import Services from "./Services";
import OurService from "../Home1/OurService";

class OurServicePage extends Component{
    state ={
        bgColor: '#f2f3f5'
    }
    render() {
        return(
            <div>
                <Header/>
                <HeroArea slug='WHO WE ARE' title='Our Services'/>
                <Services/>
                {/*<OurService/>*/}
            </div>
        )
    }
}
export default OurServicePage;