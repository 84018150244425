import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";

// 22-Jan-2023 - SC - Removed one grid related to Skype Id
// lg={3} changed to ld={4} so that remaining three grid should be centrally alligned


class ContactInfo extends Component {
    render() {
        return (
            <section className="contact-page-wrap section-padding">
                <Grid container spacing={3} className="contact-deatils websitePadding">
                    <Grid item lg={4} sm={6} className="">
                        <Grid className="single-contact-details">
                            <h3>Call Us</h3>
                            <p>+1 778 246-3365</p>
                            <p></p>
                        </Grid>
                    </Grid>
                    <Grid item lg={4} sm={6} className="">
                        <Grid className="single-contact-details">
                            <h3>Address</h3>
                            <p>Vancouver, Burnaby, Richmond, <br/>Delta, Surrey, Langly</p>
                        </Grid>
                    </Grid>
                    <Grid item lg={4} sm={6} className="">
                        <Grid className="single-contact-details">
                            <h3>Email</h3>
                            <p>ramandeol3365@gmailcom</p>
                            <p></p>
                        </Grid>
                    </Grid>
                </Grid>
            </section>
        )
    }
}

export default ContactInfo