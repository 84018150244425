import React, {Component} from "react";
import OurService1 from "../../../images/wedo/w1.png";
import OurService2 from "../../../images/wedo/w2.png";
import OurService3 from "../../../images/wedo/w3.png";
import OurService4 from "../../../images/wedo/w4.png";
import Grid from "@material-ui/core/Grid";

const ServiceItem = [
    {
        id: 1,
        title: 'Drywall',
        image: OurService1,
        discription: '- Installation of Drywall, Drywall Repair, Fire stopping, exterior  drywall (Den-glass), Soundproof drywall, taping and finishing, Level 5 finishing.'
    },
    {
        id: 2,
        title: 'Texture',
        image: OurService2,
        discription: '- Spray texture ceiling, - Remove old spray and popcorn ceiling, - repair spray and popcorn ceiling.'
    },
    {
        id: 3,
        title: 'Steel Framing',
        image: OurService3,
        discription: '- Interior steel framing, - Framing for drop celining, - T bar.'
    },
    {
        id: 4,
        title: 'Insulation',
        image: OurService4,
        discription: '- All types of batt solution., -Sound Insulation, - Vapor Barrier '
    },
];

class Services extends Component {
    render() {
        return (
            <section className="what-we-do section-padding">
                <Grid container spacing={3} className="websitePadding">
                    <Grid item lg={1}></Grid>
                    <Grid item lg={10} xs={12} className="text-center">
                        <Grid className="what-we-do-title">
                            <span>what we do</span>
                            <h2>We bring design to life using our time tested Drywall Solutions and <span>Best services is our commitment.</span>
                            </h2>
                        </Grid>
                    </Grid>
                    <Grid key='1' item md={3} sm={6} className="text-center">
                        <Grid className="single-we-do">
                            <img src={OurService1} alt=""/>
                            <h3>Drywall</h3>
                            <p align="left">We provide drywall installtion, finishing and repair work.<br/><br/>
                                - Installation of Drywall<br/>
                                - Drywall Repair<br/>
                                - Fire stopping<br/>
                                - Exterior  drywall (Den-glass)<br/>
                                - Soundproof drywall<br/>
                                - Taping and finishing<br/>
                                - Level 5 finishing.</p>
                        </Grid>
                    </Grid>
                    <Grid key='2' item md={3} sm={6} className="text-center">
                        <Grid className="single-we-do">
                            <img src={OurService2} alt=""/>
                            <h3>Texture</h3>
                            <p align="left">We do spray ceiling, reapir old spray and popcorn ceiling.<br/><br/>
                                - Spray texture ceiling<br/>
                                - Remove old spray and popcorn ceiling<br/>
                                - repair spray and popcorn ceiling</p>
                        </Grid>
                    </Grid>
                    <Grid key='3' item md={3} sm={6} className="text-center">
                        <Grid className="single-we-do">
                            <img src={OurService3} alt=""/>
                            <h3>Steel Framing</h3>
                            <p align="left">We provide structural and light gauge steel framing.<br/><br/>
                                - Interior steel framing<br/>
                                - Framing for drop celining<br/>
                                - T bar</p>
                        </Grid> 
                    </Grid>
                    <Grid key='4' item md={3} sm={6} className="text-center">
                        <Grid className="single-we-do">
                            <img src={OurService4} alt=""/>
                            <h3>Insulation</h3>
                            <p align="left">We provide all kind of insulation services.<br/><br/>
                                - All types of batt solution<br/>
                                - Sound Insulation<br/>
                                - Vapor Barrier</p>
                        </Grid>                                                                                                  
                    </Grid>
                </Grid>
            </section>
        )
    }
}
export default Services;