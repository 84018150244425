import React from 'react';
import {BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import HomePage from "./component/Home1/Page";
import Footer from "./component/Home1/Footer";
import aboutPage from "./component/About/aboutPage";
import ContactPage from "./component/Contact/ContactPage";
import PortfolioPage from "./component/Portfolio/PortfolioPage";
import OurServicePage from "./component/OurService/OurServicePage";
import ServiceDetailsPage from "./component/SerciceDetails/serviceDetailsPage";

let ServicePath = window.location.href;

class App extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      siteUrl: window.location.pathname
    }
  }

  handleClick() {
   this.setState({
     siteUrl : window.location.pathname
   });
  }
  render() {
    return (
        <div onClick={this.handleClick.bind(this)} className="App">
          <div className="main-wrapper">
            <Router>
              <Switch>
                <Route exact path='/' component={HomePage}/>
                <Route path='/contact' component={ContactPage}/>
                <Route path='/portfolio' component={PortfolioPage}/>
                <Route path='/services' component={OurServicePage}/>
                <Route path='/servicesdetails' component={ServiceDetailsPage}/>
              </Switch>
              <Footer websiteUrl={this.state.siteUrl}/>
            </Router>
          </div>
        </div>
    );
  }
}
export default App;
