import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";

class ContactMap extends Component {
    render() {
        return (
            <section class="google-map-wrap">
                <Grid container spacing={3} class="websitePadding">
                    <Grid item lg={12} class="">
                        <Grid class="gmap">
                            <iframe
                                src="https://maps.google.com/maps?q=delta%20british&t=&z=13&ie=UTF8&iwloc=&output=embed"
                                allowfullscreen></iframe>
                        </Grid>
                    </Grid>
                </Grid>
            </section>
        )
    }
}

export default ContactMap;